// Products.js
import React from 'react';
import './Products.css';
import ActionAreaCard from '../card/ActionAreaCard';

const Products = ({ productsData, maxWidth }) => {
    const handleCardClick = (category) => {
        console.log(`Clicked on ${category}`);
    };

    return (
            <div className="products-container">
                {productsData.map((product, index) => (
                    <ActionAreaCard
                        key={index}
                        onClick={() => handleCardClick(product.category)}
                        image={product.imageSrc}
                        title={product.category}
                        description={product.description}
                        buttonProps={product.buttonProps}
                        className="product-card"
                        maxWidth={maxWidth}
                    />
                ))}

            </div>

    );
};

export default Products;
