// SocialMediaWidget.js
import React from 'react';

const SocialMediaWidget = () => {
    return (
        <div className="social-media-widget">
            {/* Facebook Page Plugin */}
            <div>
                <iframe
                    title="Facebook Page"
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FArtndTechnology%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
                    width="340"
                    height="380"
                    style={{ border: 'none', overflow: 'hidden' }}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
            </div>
        </div>
    );
};

export default SocialMediaWidget;
