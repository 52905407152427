// Slider.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';
import { motion } from 'framer-motion';

const MySlider = ({ settings, images }) => {
    const sliderSettings = {
        ...settings,
    };

    return (
        <div className="slider-container">
            <Slider {...sliderSettings} className="custom-slider">
                {Array.isArray(images) ? (
                    images.map((image, index) => (
                        <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                            <img src={image} alt={`${index + 1}`} className="slider-image" />
                        </motion.div>
                    ))
                ) : (
                    <div>Error: Las imágenes no están disponibles.</div>
                )}
            </Slider>
        </div>
    );
};

export default MySlider;