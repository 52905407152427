// es.js
const messages = {
    header: {
        logo: 'Logo',
        home: 'Inicio',
        about: 'Acerca de',
        contact: 'Contacto',
    },
    main: {
        fusionText: 'Fusionando Arte y Tecnología',
        transformText: 'Transformamos ideas creativas en experiencias tecnológicas innovadoras.',
        aboutWorkTitle: 'Descubre Nuestro Trabajo',
        aboutWorkDescription: 'Explora nuestra variedad de proyectos que abarcan distintas tecnologías:',
        partnersTitle: 'Nuestros Clientes y Aliados estratégicos',
        partnersDescription: 'Colaboramos con diversas industrias, ofreciendo asesoramiento para materializar sus ideas y conceptos.',
        publicationsTitle: 'Nuestras Publicaciones',
        publicationsDescription: 'Descubre y adquiere nuestros libros, pósters y diseños exclusivos en nuestro catálogo en línea.',
        contactUsTitle: 'Contáctanos y resolvemos tus dudas',
        contactUsDescription: 'Estás a un chat de distancia...',
        contactLink: 'Contáctanos',
        goToURL: 'Saber más',
        goToAnotherURL: 'Empezar AR',
        URL2: 'Comprar',
    },
    footer: {
        copyright: '© 2024 Art&Technology',
    },
};

export default messages;
