// Services.js
import React from 'react';
import './Services.css';
import ActionAreaCard from '../card/ActionAreaCard';

const Services = ({ servicesData, maxWidth }) => {
    const handleCardClick = (category) => {
        console.log(`Clicked on ${category}`);
    };

    return (
        <div className="services-container">
            {servicesData.map((service, index) => (
                <ActionAreaCard
                    key={index}
                    onClick={() => handleCardClick(service.category)}
                    image={service.imageSrc}
                    title={service.category}
                    description={service.description}
                    className="service-card"
                    maxWidth={maxWidth}
                />
            ))}
        </div>
    );
};

export default Services;
