// ContactUs.js
import React, { useState } from 'react';
import { Paper, TextField, Button } from '@mui/material';
import './ContactUs.css';
import SocialMediaWidget from '../social/SocialMediaWidget'; // Importa tu nuevo componente

const ContactUs = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const createWhatsAppLink = () => {
        const { name, message, email } = formData;
        const whatsappMessage = `Hola, mi nombre es ${name}. Mi mensaje es: ${message}. Mi correo es: ${email}`;
        return `https://wa.me/3203231701?text=${encodeURIComponent(whatsappMessage)}`;
    };

    const sendEmail = () => {
        const { name, email, message } = formData;
        const emailBody = `Hola,\n\nMi nombre es ${name}.\nMi correo es: ${email}\nMi mensaje es:\n${message}`;
        const emailSubject = 'Mensaje desde el formulario de contacto';
        window.location.href = `mailto:destinatario@example.com?subject=${encodeURIComponent(
            emailSubject
        )}&body=${encodeURIComponent(emailBody)}`;
    };

    return (
        <div className="contact-us-container">
            <div className="flex-container">
                {/* Sección izquierda */}
                <div className="left-section">
                    <SocialMediaWidget />
                </div>

                {/* Sección derecha */}
                <div className="right-section">
                    <Paper elevation={3} className="contact-us-paper">
                        <form>
                            <TextField
                                label="Nombre"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <TextField
                                label="Correo Electrónico"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <TextField
                                label="Mensaje"
                                name="message"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <div className="button-container">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    href={createWhatsAppLink()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    WhatsApp
                                </Button>
                                <Button variant="contained" color="primary" onClick={sendEmail}>
                                    Enviar Correo
                                </Button>
                            </div>
                        </form>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
