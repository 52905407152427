// OurPartners.js
import React from 'react';
import './OurPartners.css';
import MySlider from "../slider/Slider";

const OurPartners = ({ partnerData }) => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 900, // Ajusta la velocidad de las transiciones
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        fade: false,
        autoplay: true,
        autoplaySpeed: 2000, // Ajusta la velocidad de cambio automático
    };

    return (
        <div className="partners-container">
            <MySlider settings={sliderSettings} images={partnerData.map((partner) => (partner.logo))} />
        </div>
    );
};

export default OurPartners;