// App.js
import React from 'react';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MySlider from "./app/components/slider/Slider";
import Header from "./app/components/header/Header";
import Presentation from "./app/components/card/Presentation";
import SpaceBetween from "./app/components/SpaceBetween";
import Services from "./app/components/services/Services";
import AboutUs from "./app/components/ourwork/AboutUs";
import OurPartners from "./app/components/partners/OurPartners";
import Products from "./app/components/Products/Products";
import messages from './es';
import ContactUs from "./app/components/contact/ContactUs";

function App() {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        fade: true,
        autoplay: true,
    };

    const sliderImages = [
        "images/slider/33.gif",
        "images/slider/1.jpg",
        "images/slider/2.jpg",
        "images/slider/8.jpg",
    ];

    const logoSrc = "images/white-logo.png";
    const presentationData = {
        title: messages.main.fusionText,
        description: messages.main.transformText,
    };

    const titleOurWorkData = {
        title: messages.main.aboutWorkTitle,
        description: messages.main.aboutWorkDescription,
    };

    const titleOurPartners = {
        title: messages.main.partnersTitle,
        description: messages.main.partnersDescription,
    };

    const titlePublication = {
        title: messages.main.publicationsTitle,
        description: messages.main.publicationsDescription,
    };

    const titleContactUs = {
        title: messages.main.contactUsTitle,
        description: messages.main.contactUsDescription,
    };

    const servicesData = [
        {
            category: 'Desarrollo de Software',
            imageSrc: "images/software.png",
            description: 'Transformamos ideas en software personalizado y eficiente para potenciar tu negocio.',
        },
        {
            category: 'Producción Creativa',
            imageSrc: "images/design.png",
            description: 'Damos vida a tus ideas con diseño gráfico y producción multimedia impactante y de calidad.',
        },
        {
            category: 'Marketing Digital',
            imageSrc: "images/marketing.png",
            description: 'Optimizamos tu presencia en línea y potenciamos tu marca a través de estrategias de marketing digital.',
        },
    ];

    const aboutUsData = [
        {
            title: 'Realidad Aumentada',
            mediaPreview: "images/us/11.gif", // Reemplaza con la URL del video
            description: 'Sumérgete en un universo donde la realidad se fusiona con la imaginación. ¿Quieres un poster con realidad aumentada? Escríbenos y lo hacemos para ti',
            url: "https://www.facebook.com/edgabrieled/videos/881326543564017/"
        },
        {
            title: 'Inteligencia Artificial',
            mediaPreview: "images/us/22.gif",
            description: 'Adéntrate en el fascinante mundo de la Inteligencia Artificial. Mis proyectos impulsados por IA desafían los límites de la creatividad y la innovación.',
            url: "https://www.instagram.com/reel/C0H6P7vg9KE/?utm_source=ig_web_copy_link"
        },
        {
            title: 'Realidad Virtual',
            mediaPreview: "images/us/33.gif",
            description: 'Explora experiencias envolventes y vívidas con nuestra tecnología de Realidad Virtual. Sumérgete en mundos virtuales que desafían los límites de la imaginación y la innovación.',
            url: "https://vr.artntech.com.co/ArtnTech/portal-default.html"
        },
        {
            title: 'Páginas web',
            mediaPreview: "images/us/44.gif",
            description: 'Diseñamos y desarrollamos páginas web impactantes y funcionales que se adaptan a tus necesidades. Desde elegantes sitios de presentación hasta potentes plataformas interactivas, llevamos tus ideas a la web con creatividad y tecnología de vanguardia.',
            url: "#"
        }
    ];

    const partnerData = [
        { name: 'Cliente 1', logo: "images/partners/1.png" },
        { name: 'Cliente 2', logo: "images/partners/2.png" },
        { name: 'Cliente 3', logo: "images/partners/3.png" },
        { name: 'Cliente 4', logo: "images/partners/4.png" },
        { name: 'Cliente 5', logo: "images/partners/5.png" },
        { name: 'Cliente 6', logo: "images/partners/6.png" },
        { name: 'Cliente 7', logo: "images/partners/7.png" },
        { name: 'Cliente 8', logo: "images/partners/8.png" },
    ];

    const productsData = [
        {
            category: 'Continuidad del horizonte',
            imageSrc: "images/posts/cdh.jpg",
            description: '\'Selección de textos narrativos de escritores del Meta. El libro fue compilado bajo la producción general de Entreletras.',
            buttonProps: {
                firstText: messages.main.goToURL,
                firstUrl: "https://fb.watch/pAcR8SKWqT/",
                secondText: messages.main.URL2,
                secondUrl: "https://wa.me/3203231701",
            }
        },
        {
            category: 'A la hora de contar',
            imageSrc: "images/posts/alhdc.jpg",
            description: 'Voces que descubren en las historias, relatos y personajes, la magia que existe a la hora de contar. Producción bajo el sello editorial Entreletras.',
            buttonProps: {
                firstText: messages.main.URL,
                firstUrl: "https://www.facebook.com/insculturameta/videos/535202391019469/",
                secondText: messages.main.URL2,
                secondUrl: "https://wa.me/3203231701",
            },
        },
        {
            category: 'Galería AI',
            imageSrc: "images/posts/22.gif",
            description: 'Adquiere tus obras de la Galería AI en diferentes tamaños y calidades, cotiza tu diseño.',
            buttonProps: {
                firstText: messages.main.goToAnotherURL,
                firstUrl: "https://ar.artntech.com.co/taller/",
                secondText: messages.main.URL2,
                secondUrl: "https://wa.me/3203231701",
            },
        }
        ];

    return (
        <div className="App">
            <Header logoSrc={logoSrc} messages={messages.header} />
            <main className="App-main">
                <MySlider settings={sliderSettings} images={sliderImages} />
                <section id="home">
                    <SpaceBetween margin="10px 0">
                        <Presentation {...presentationData} />
                    </SpaceBetween>
                    <section id="services">
                        <Services servicesData={servicesData} maxWidth={300} />
                    </section>
                    <Presentation {...titleOurWorkData} />
                    <section id="our-work" >
                        <section id="about"></section>
                        <AboutUs aboutData={aboutUsData}/>
                    </section>
                    <Presentation {...titleOurPartners} />
                    <section id="our-work">
                        <OurPartners partnerData={partnerData}/>
                    </section>
                    <Presentation {...titlePublication} />
                    <section id="products">
                        <Products productsData={productsData} maxWidth={300} />
                    </section>
                    <Presentation {...titleContactUs} />
                    <section id="contact-us">
                        <ContactUs></ContactUs>
                    </section>
                </section>
            </main>

            <footer className="App-footer" id="contact">
                <p>{messages.footer.copyright}</p>
            </footer>
        </div>
    );
}

export default App;
