// SpaceBetween.js
import React from 'react';

const SpaceBetween = ({ margin, children }) => {
    const style = {
        margin,
        display: 'flex',
        justifyContent: 'center',
    };

    return <div style={style}>{children}</div>;
};

export default SpaceBetween;
