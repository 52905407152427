// AboutUs.js
import React from 'react';
import {Container, Paper} from '@mui/material';
import OurWork from '../ourwork/OurWork';
import './AboutUs.css';

const AboutUs = ({ aboutData }) => {
    return (
        <div className="services-container">
            <Container className="about-us-container">
                {aboutData.map((work, index) => (
                    <OurWork
                        key={index}
                        mediaPreview={work.mediaPreview}
                        title={work.title}
                        description={work.description}
                        url={work.url}
                    />
                ))}
            </Container>
            <Paper elevation={3} />
        </div>
    );
};

export default AboutUs;
