import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import './OurWork.css';

const OurWork = ({ mediaPreview, title, description, url }) => {
    const [isFavorite, setIsFavorite] = useState(false);

    const handleFavoriteClick = () => {
        setIsFavorite(!isFavorite);
    };

    const handleShareClick = () => {
        // Agrega aquí la lógica para manejar el clic en el botón de compartir, por ejemplo, abrir un modal
        console.log(`Compartiendo ${title} en ${url}`);
    };

    return (
        <Card className="our-work-card">
            <CardMedia
                component="img"
                id="fullscreen-media"
                height="200px"
                width="200px"
                image={mediaPreview}
                alt={title}
                onError={(e) => {
                    e.target.src = 'images/logo.png';
                }}
            />
            <CardContent className="our-work-card-content">
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <div className="our-work-actions">
                <IconButton aria-label="add to favorites" onClick={handleFavoriteClick}>
                    {isFavorite ? (
                        <FavoriteIcon style={{ color: 'red' }} />
                    ) : (
                        <FavoriteBorderIcon />
                    )}
                </IconButton>
                <IconButton aria-label="share" onClick={handleShareClick}>
                    <a href={url} target="_blank" rel="noopener noreferrer" className="share-link">
                        <ShareIcon />
                    </a>
                </IconButton>
            </div>
        </Card>
    );
};

export default OurWork;
