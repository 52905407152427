import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import './Presentation.css';

function Presentation({ imageSrc, title, description, isInfoSection }) {
    const containerStyle = isInfoSection ? { textAlign: 'center' } : {};

    return (
        <Container className="presentation-container" style={containerStyle}>
            {imageSrc && <img src={imageSrc} alt="Presentation" className="presentation-image" />}
            <div className="presentation-content">
                <Typography variant="h4" component="h2" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    {description}
                </Typography>
            </div>
        </Container>
    );
}

Presentation.propTypes = {
    imageSrc: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isInfoSection: PropTypes.bool,
};

export default Presentation;
