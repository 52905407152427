// ActionAreaCard.js
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Button, CardActionArea, CardActions, Paper} from '@mui/material';

export default function ActionAreaCard({ image, title, description, buttonProps, maxWidth }) {


    return (
        <div className="presentation-container">
            <Paper elevation={24} />
            <Card sx={{ maxWidth }}>
                <CardActionArea>
                    <CardMedia component="img" height="200" image={image} alt={image} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {buttonProps && buttonProps.firstText && (
                            <Button href={buttonProps.firstUrl} target="_blank" rel="noopener noreferrer">
                                {buttonProps.firstText}
                            </Button>
                        )}
                        {buttonProps && buttonProps.secondText && (
                            <Button href={buttonProps.secondUrl} target="_blank" rel="noopener noreferrer">
                                {buttonProps.secondText}
                            </Button>
                        )}
                    </CardActions>
                </CardActionArea>
            </Card>
        </div>
    );
}
