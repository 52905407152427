import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Header.css';

function Header({ logoSrc }) {
    const [transparent, setTransparent] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        const triggerOffset = 10; // Ajusta este valor según tus necesidades
        setTransparent(offset <= triggerOffset);
    };

    const handleWheel = (event) => {
        const zoomLevel = event.deltaY > 0 ? 1 : -1;
        const isMaxZoom = document.documentElement.clientWidth <= window.innerWidth || zoomLevel < 0;
        setTransparent(!isMaxZoom);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('wheel', handleWheel);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    const openWhatsApp = () => {
        const phoneNumber = '3203231701';
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };


    return (
        <header className={`header-container ${transparent ? '' : 'scrolled'}`}>
            <img src={logoSrc} alt="Logo" className="header-logo" />
            <nav className={`header-nav ${transparent ? 'transparent' : ''}`}>
                <ul>
                    <li><a href="#home">Inicio</a></li>
                    <li><a href="#about">Acerca de</a></li>
                    <li><a href="#contact" onClick={openWhatsApp}>Contacto</a></li>
                </ul>
            </nav>
        </header>
    );
}

Header.propTypes = {
    logoSrc: PropTypes.string.isRequired,
};

export default Header;
